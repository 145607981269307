import React from 'react'
import { styled } from '../../../../stitches.config'
import { IncludeAdaText } from '@/src/common/utilities/includeAdaText'

export interface FAQProps {
    faqHeader: string
    faqText: string
    includeChatBot: boolean
}

const Box = styled('div', {
    marginBottom: '.75rem',
    paddingBottom: '1.75rem',
    marginTop: '.15rem',
    maxWidth: '1000px',
    borderBottom: '1px solid #EDEEEF',
    summary: {
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: '1.75rem',
        listStyleType: 'none',
        display: 'flex',
        cursor: 'pointer',
        color: '$darkest',
        textAlign: 'left',
    },
    'summary::before': {
        content: "'+'",
        paddingRight: '1rem',
        fontSize: '1.5rem',
        fontWeight: 400,
        color: '$primary',
        listStyle: 'none',
    },
    'summary::marker': {
        display: 'none',
    },
    /* For Safari */
    'summary::-webkit-details-marker': {
        display: 'none',
    },
    'details[open]': {
        '& div': {
            padding: '0 1.75rem',
            lineHeight: '1.5rem',
        },
        '& a': {
            color: '#A800FF',
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        'summary::before': {
            content: "'–'",
            paddingRight: '1rem',
            fontSize: '1.3rem',
            fontWeight: 600,
            color: '$dark',
        },
        '& p': {
            textAlign: 'left',
        },
    },
})

const FAQSection = ({ faqHeader, faqText, includeChatBot }: FAQProps) => {
    return (
        <Box
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
        >
            <details>
                <span itemProp="answerCount" style={{ display: 'none' }}>
                    1
                </span>
                <summary data-testid={'faqHeader'} itemProp="name">
                    {faqHeader}
                </summary>
                <div
                    itemProp="acceptedAnswer"
                    itemType="https://schema.org/Answer"
                    data-testid={'faqText'}
                >
                    <div
                        itemProp="text"
                        dangerouslySetInnerHTML={{ __html: faqText }}
                        style={{
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                            padding: 0,
                        }}
                    />

                    {!includeChatBot && <IncludeAdaText />}
                </div>
            </details>
        </Box>
    )
}

export default FAQSection
